import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tippy";
import { isMobile } from "helpers/Mobile";
import Tag from "components/MainApp/organisms/OrdersV3/Tag";
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import "./styles.scss";
import { ProductService } from "services/ProductService";
import { ContainerService } from "services/ContainerService";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
};
const ItemStock = ({ stock, list, tagsInMov = [] }) => {
  const [row, setRow] = useState({});
  const [opened, setOpened] = useState(false);
  const columns = ["title", "sku", "tags"];

  useEffect(() => {
    const newRow = {
      ...stock,
      tags: `${stock.readItems} / ${stock.expectedUnits}`,
    };
    setRow(newRow);
    console.log(newRow)
    try{
      if(stock.item_id) {
        ContainerService.container(stock.item_id).then((resp) => {
          ProductService.product(resp.results.relations[0].id).then((prod)=> {
            if(prod.item_files){
              stock.image = prod.item_files[0].file_name;
              const newRow = {
                ...stock,
                tags: `${stock.readItems} / ${stock.expectedUnits}`,
              };
              setRow(newRow);
            }
          })
          console.log(resp.results.relations[0])
        })
      }
    }catch{
      // no es una caja
    }
  }, [stock]);

  const handleClick = () => {
    const openedAux = opened;
    setOpened(!openedAux);
  };



  const containsExpirated = () => {
    return list.some(item => {
      if (item.variables) {
        const expirationDate = new Date(item.variables.expiration);
        return expirationDate < new Date();
      }
      return false;
    });
  }

  const getTotalItemsBox = () =>{
    let total = 0;
    list.forEach(tag => {
      if(tag.variables && tag.variables.cant_items_box){
        total = total + tag.variables.cant_items_box
      }else {
        total = total+1
      }
    });
    return total;
  }


  const renderColumn = (key) => {
    return (
      <div
        className={`reading-table-item-${key} reading-table-item`}
        data-stock-id={key}
        onClick={handleClick}
        key={key}
      >
        <Tooltip
          html={<div style={tooltipStyle}>
            {key === "tags"
              ? `${stock.readItems} / ${stock.expectedUnits}`
              : stock[key]}
          </div>}
          followCursor={true}
          position="left"
          offset={25}
        >
          <p class="container_icons">
            {key === "title" && <img src={stock.image} />}
            {key === "tags"
              ? `${stock.readItems} / ${stock.expectedUnits}`
              : stock[key]}
            {key === "tags" && (
              stock.expectedUnits === 0 ? <span className="icons question_blue">?</span> :
                stock.readItems === stock.expectedUnits ? <i className="icons icon-check"></i> :
                  stock.readItems < stock.expectedUnits && stock.readItems >= 1 ? <i className="icons icon-iconos_menos"></i> :
                    <i className="icons icon-cruz"></i>
            )}
          </p>
        </Tooltip>
      </div>
    );
  };

  return (
    <div
      className="reading-table-item-wrapper"
      data-automation-id="stock-item-wrapper"
    >
      {!isMobile && (
        <div
          className="reading-table-item-container"
          data-automation-id="stock-item"
        >
          {columns.map((column_key) => {
            return renderColumn(column_key);
          })}

          <div
            className={`reading-table-item-button ${opened ? "opened" : ""}`}
            data-automation-id="select-stock"
            data-stock-id={stock.sku}
            onClick={handleClick}
            key={"button"}
          >
            <i className="icon-chevron"></i>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="reading-table-item-mobile-container">
          <div className={"reading-table-item-row"} onClick={handleClick}>
            <div className={"reading-table-item-column image"}>
              <div>
                <img src={stock.image} />
              </div>
            </div>
            <div className={"reading-table-item-column title"}>
              <h1>{stock.title}</h1>
              <p>SKU {stock.sku}</p>
            </div>
            <div>
              {containsExpirated() &&
                <EventBusyOutlinedIcon className="expirated-icon" />
              }
              {(
                stock.expectedUnits === 0 ? <span className="icons question_blue">?</span> :
                  stock.readItems === stock.expectedUnits ? <i className="icons icon-check"></i> :
                    stock.readItems < stock.expectedUnits && stock.readItems >= 1 ? <i className="icons icon-iconos_menos"></i> :
                      <i className="icons icon-cruz"></i>
              )}
            </div>
            <div className={"reading-table-item-column button"}>
              <div
                className={`reading-table-item-button ${opened ? "opened" : ""
                  }`}
                data-automation-id="select-stock"
                data-stock-id={stock.sku}
                key={"button"}
              >
                <i className="icon-chevron"></i>
              </div>
            </div>
          </div>
          <div className={"reading-table-item-row tags"}>
            <div className="total-items-audit">
              <p>items total: <span>{getTotalItemsBox() * stock.readItems}</span>/{getTotalItemsBox()}</p>
            </div>
            <p>Tags <span>{stock.readItems}</span>/{stock.expectedUnits}</p>
          </div>
        </div>
      )}

      <div className={`reading-table-list ${opened ? "opened" : ""}`}>
        <div className={"reading-table-tags-separator"}></div>
        {list.map((tag) => {
          return (
            <Tag
              tag={tag}
              epc={tag.epc}
              type="verification"
              selected={tag.selected}
              message={tag.message}
              key={tag.tag}
              tagsInMov={tagsInMov}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ItemStock;
