function isHex(h) {
  var regexp = /^[0-9a-fA-F]+$/;
  return regexp.test(h);
}

const getDateExpiration = (expiration) => {
  try{
    var dateParts = expiration.split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).getTime();
  }catch{
    return new Date(1,1,2099);
  }
}


/**
 * If the number is a hex prepend 0x to the number
 * @param {*} number
 * @returns BigInt
 */
function toBigInt(number) {
  if(!Number.isInteger(number) && !isHex(number)){
    console.error('toBigInt Invalid number:', number);
    throw new Error('Invalid number');
  }

  if(isHex(number)) number = "0x" + number;
  return BigInt(number);
}

/**
 * Comprueba que un valor tenga contenido sea distinto de null, undefined o vacío
 *
 * @param {*} value
 * @returns boolean
 */
const isNotNullOrWhitespace = (value) => {
  return value && value.trim() !== "";
};

/**
 * Comprueba que un array de valores tengan contenido
 *
 * @param {*} values
 * @returns
 */
const checkMultipleIsNotNullOrWhitespace = (values) => {
  return values.every(isNotNullOrWhitespace);
};

export { toBigInt, isNotNullOrWhitespace, checkMultipleIsNotNullOrWhitespace, getDateExpiration};