import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { contokVisible, isMobile, itectorVisible } from "helpers/Mobile";
import { AuthService } from "services/AuthService";

import MainButton from "../../atoms/RoundedButton";
import Tooltip from "../../atoms/Tooltip";
import Icon from "components/MainApp/atoms/Icon/icon";

import "./styles.scss";
const Header = ({
  goBackFunc,
  title,
  options,
  optionsIcons,
  buttonLabel,
  buttonHandler,
  buttonState,
  content,
  closeFunc,
  hide,
}) => {
  const [node, _] = useState(AuthService.currentNodeValue());
  const history = useHistory();

  const mainContent = (
    < div className="header-container" >
      <div className="left-container">
        {goBackFunc && (
          <i
            className="icon-go-back-desktop icon-chevron-izquierda"
            onClick={goBackFunc}
            data-automation-id="go-back-header"
          ></i>
        )}
        {goBackFunc && (
          <i
            className="icon-go-back-mobile icon-back"
            onClick={goBackFunc}
            cdata-automation-id="go-back-header"
          ></i>
        )}
        {closeFunc && (
          <i
            className="icon-go-back-desktop icon-cruz"
            onClick={closeFunc}
            data-automation-id="go-back-header"
          ></i>
        )}
        {closeFunc && (
          <i
            className="icon-go-back-mobile icon-cruz"
            onClick={closeFunc}
            cdata-automation-id="go-back-header"
          ></i>
        )}
        <div className={goBackFunc ? "title-go-back" : "title"}>{title}</div>
      </div>
      <div className="right-container">
        {content && content}
        {buttonLabel &&
          !isMobile && (
            <div className="header-button">
              <MainButton
                legend={buttonLabel}
                state={buttonState}
                handleClick={buttonHandler}
              ></MainButton>
            </div>
          )}
        {optionsIcons?.map((option) => {
          return (
            <div
              className="header-item"
              key={option.name}
              onClick={option.handler}
              data-automation-id="menu-option"
            >
              <Tooltip
                content={<Icon
                  className={"icon " + option.icon}
                  name={option.icon}
                  fill={isMobile ? "#fff" : "#3C7AF5"}
                  width={23}
                  height={27}
                />}
                text={option.tooltip}
              ></Tooltip>
            </div>
          );
        })}
        {options.map((option) => {
          if (option.name?.match(/(shelving)/) && (!isMobile || !itectorVisible)) return;
          if (option.name?.match(/(contok)/) && (!isMobile || !contokVisible)) return;
          return (
            <div
              className="header-item"
              key={option.name}
              onClick={option.handler}
              data-automation-id="menu-option"
            >
              <Tooltip
                content={option.icon ?
                  <i className={"icon " + option.icon}></i>
                  :
                  <img src={option.ic}></img>
                }
                text={option.tooltip}
              ></Tooltip>
            </div>
          );
        })}
        
        <div
          className="header-item sucursal"
          key={"sucursal"}
          onClick={() => {
            history.push("/admin/active-node");
          }}
          data-automation-id="menu-option"
        >
          <Tooltip
            content={
              <div className={"icon"}>
                <Icon
                  className={"icon sucursal"}
                  name={isMobile ? "sucursal" : "sucursal-azul"}
                  fill={isMobile ? "#fff" : "#3C7AF5"}
                  width={23}
                  height={27}
                />
                {!isMobile && (
                  <p>
                    Sucursal{" "}
                    {node ? ` ${node.name}` : ""}
                  </p>
                )}
              </div>
            }
            text={"Sucursal"}
          ></Tooltip>
        </div>
      </div>
    </div >
  )

  return (
    <div>
      {!hide && mainContent}
    </div>
  );
};

export default Header;
