import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../Sidebar";
import Header from "../HeaderMobile";

import "./styles.scss";
export class DesktopMainLayout extends React.Component {
  render() {
    const {
      content,
      headerTitle,
      headerOptions,
      headerOptionsIcons,
      goBackFunc,
      navHidden,
      headerButtonLabel,
      headerButtonHandler,
      headerButtonState,
      customButtons,
      closeFunc,
      hide = false,
    } = this.props;

    return (
      <div className="desktop-main-layout-container">
        <div className="desktop-nav">
          <Sidebar></Sidebar>
        </div>
        <div className="right-layout-container">
          <Header
            title={headerTitle}
            options={headerOptions}
            optionsIcons={headerOptionsIcons}
            goBackFunc={goBackFunc}
            buttonLabel={headerButtonLabel}
            buttonHandler={headerButtonHandler}
            buttonState={headerButtonState}
            content={customButtons}
            closeFunc={closeFunc}
            hide={hide}
          ></Header>
          {content}
        </div>
        {!navHidden && (
          <div className="mobile-nav">
            <Sidebar></Sidebar>
          </div>
        )}
      </div>
    );
  }
}

DesktopMainLayout.propTypes = {
  content: PropTypes.any,
};

export default DesktopMainLayout;
