
import "./TagVariables.scss";

const TagVariables = ({ variables }) => {

    const getDateExpiration = (expiration) => {
        try {
            var dateParts = expiration.split("-");
            return new Date(expiration).toLocaleDateString("es-ES", { day: '2-digit', month: '2-digit', year: 'numeric' });
        } catch {
            return new Date(1, 1, 2099);
        }
    }

    const isExpired = (expirationDate) => {
        let currentDate = new Date()
        return new Date(expirationDate) < currentDate;
    }

    return (
        <div>
            <div>
                Caducidad: {getDateExpiration(variables.expiration)}
                {isExpired(variables.expiration) &&
                    <span className="expirated">caducado</span>
                }
            </div>
            <div>
                Lote: {variables.batch}
            </div>
        </div>
    )
}

export default TagVariables;